import './LegalPage.scss';

export default function LegalPage() {

  return (
    <div className="legal-page">
      <h1>Impressum</h1>

      Angaben gemäß § 5 TMG:<br/><br/>

      Susana Maria Cintrano Lopez<br/>
      Friseur<br/>
      Stubenrauchstr. 9<br/>
      12161 Berlin<br/>
      <br/>
      Kontakt:<br/>
      Telefon: +49 03 859 12 52<br/>
      Telefax: +49 03 859 12 52<br/>
      E-Mail: info@haarkreation-lopez.de<br/>
      <br/>
      Registereintrag:<br/>
      Eintragung im Handelsregister.<br/>
      Registergericht: AG Schöneberg<br/>
      Registernummer: 123124<br/>
      Umsatzsteuer-ID: 119068<br/>
      <br/>
      Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br/>
      90279501<br/>
      Aufsichtsbehörde:<br/>
      Handwerkskammer<br/>
      <br/>
      Berufsbezeichnung: Friseurmeisterin<br/>
      Zuständige Kammer: Handwerkskammer<br/>
      Verliehen durch: Deutschland<br/>
      Es gelten folgende berufsrechtliche Regelungen: Selbstständig<br/>
      Regelungen einsehbar unter: a<br/>
      Angaben zur Berufshaftpflichtversicherung:<br/>
      <br/>
      Name und Sitz der Gesellschaft:<br/>
      <br/>
      Concordia<br/>
      <br/>


      Quelle: Erstellt mit dem Impressum-Generator Einzelunternehmer von eRecht24.
    </div>
  );
}
