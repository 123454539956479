import {createContext, useState} from "react";
import {ContextProviderPropsType, NavBarContextType} from "./ContextProvider.types";
import {useLocation} from "react-router-dom";


export const NavBarContext = createContext<NavBarContextType>(["", () => {}]);

export default function ContextProvider({children}: ContextProviderPropsType) {
  const location = useLocation();
  const initialLocation = location.pathname.slice(1) || "";
  const [activeTab, setActiveTab] = useState(initialLocation);

  return (
    <NavBarContext.Provider value={[activeTab, setActiveTab]}>
      {children}
    </NavBarContext.Provider>
  );
}

