import {ImagePropsType} from "./Image.types";
import "./Image.scss";

export default function Image(props: ImagePropsType) {
  const {
    src,
    className = "",
    alt = "",
    link = undefined
  } = props;

  const isLink = link !== undefined;

  const imageElement = <img className={className} src={src} alt={alt} />;

  return (
    <div className="image-root">
      { isLink ? (
        <a {...link}>{imageElement}</a>
      ) : imageElement }
    </div>

  );
}
