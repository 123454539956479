import './ErrorPage.scss';
import React, {useEffect} from "react";
import { ContextProvider, Footer, NavBar, ResponsiveLogo } from '../../components';
import {redirect, useLocation} from "react-router-dom";

export default function ErrorPage() {

  const location = useLocation();
  if (location.pathname.includes(".php")) {
    const cutted = location.pathname.split(".php")[0];
    if (cutted in ["/preise", "/kontakt", "/impressum", "/datenschutz"]) {
      redirect(cutted);
    }
  } else {
    redirect("/");
  }

  return (
    <ContextProvider>
      <div className="app">
        <header className="">
          <ResponsiveLogo />

          <NavBar />
        </header>

        <div className="content-container">
          <div>
            <h1>Diese Seite existiert nicht</h1>
          </div>
        </div>

        <Footer />
      </div>
    </ContextProvider>

  );
}
