import './PricePage.scss';
import priceData from "../../prices.json";
import PriceBlock from "../../components/PriceBlock";

export default function PricePage() {

  return (
    <div>
        <h1>Preisliste</h1>

        { priceData.map((priceBlock, index) => (
          <PriceBlock title={ priceBlock.title } items={ priceBlock.items } key={`price-block-${index}`} />
        )) }
    </div>
  );
}
