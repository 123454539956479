import './WelcomePage.scss';
import Image from "../../components/Image";

export default function WelcomePage() {

  return (
    <div className="welcome-page">
      <h1>Willkommen bei Haarkreation Lopez!</h1>
      <h4>Ihr Friseursalon für individuelle Hairstyles in Berlin-Friedenau!</h4>
      <p>
        ¡Bienvenidos! Wir freuen uns, Sie auf der Website von Haarkreation Lopez begrüßen zu dürfen. Seit nunmehr 25 Jahren sind wir Ihre erste Adresse für professionelle Haarkreationen und exzellenten Service in Friedenau. Unsere Leidenschaft gilt den Haaren, denn sie sind unser natürlicher Schmuck. Daher legen wir großen Wert auf Pflege und Styling, um Ihre Persönlichkeit bestmöglich zu unterstreichen.
      </p>
      {/*<Image src="img/haare_schneiden.png" alt="Haare schneiden" />*/}
      <p>

        Gepflegtes Haar lebt länger, lässt uns gut aussehen und verleiht uns ein sicheres Gefühl. Es ist nicht nur sexy, sondern lässt uns auch jünger und strahlender in den Tag starten. Bei uns finden Sie die Expertise und das Know-how, um Ihre Haare zum Strahlen zu bringen.
      </p>
      {/*<Image src="img/gepflegtes_haar.png" alt="Gepflegtes Haar" />*/}
      <p>
        Besuchen Sie uns und entdecken Sie, wie wir Ihre Haare zu einem wahren Blickfang machen können. Wir freuen uns darauf, Ihnen einen erstklassigen Service zu bieten.
      </p>
      {/*<p>*/}
      {/*  Wir freuen uns darauf, Sie bei uns begrüßen zu dürfen.<br />*/}
      {/*  Sei es nur um Haare zu schneiden oder um einmal etwas vollkommen neues zu kreieren.<br />*/}
      {/*  Die Haare sind unser natürlicher Schmuck und wie Schmuck sollten die Haare auch gepflegt werden.<br />*/}
      {/*  Nur so können die Haare und Frisur unsere Persönlichkeit jederzeit positiv unterstreichen.<br />*/}
      {/*  Gepflegtes Haar lebt länger, lässt uns gut aussehen, gibt ein sicheres Gefühl und ist zudem nicht nur sexy, sondern lässt uns einfach jünger und strahlender in den Tag gehen.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Wir freuen uns auf Ihren Besuch*/}
      {/*</p>*/}
      <h1>Ihr Haarsalon in Berlin Friedenau.</h1>
    </div>
  );
}
