import "./Footer.scss";
import {Link} from "react-router-dom";
import {useContext} from "react";
import {NavBarContext} from "../ContextProvider";

export default function Footer() {

  const [activeTab, setActiveTab] = useContext(NavBarContext);

  return (
    <footer className="footer-container">
      <Link
        onClick={() => setActiveTab("impressum")}
        to="/impressum"
        className={`footer-button ${activeTab === "impressum" ? "active" : ""}`}
      >
        Impressum
      </Link>
      <Link
        to="/datenschutz"
        className={`footer-button ${activeTab === "datenschutz" ? "active" : ""}`}
        onClick={() => setActiveTab("datenschutz")}
      >
        Datenschutz
      </Link>
    </footer>
  );
}
