import {Link} from "react-router-dom";
import "./NavBar.scss";
import {NavBarContext} from "../ContextProvider";
import { useContext } from "react";

export default function NavBar() {

  const [activeTab, setActiveTab] = useContext(NavBarContext);

  return (
    <div className="nav-bar">
      <ul>
        <li>
          <Link
            to="/"
            className={activeTab === "" ? "active" : ""}
            onClick={() => setActiveTab("")}
          >Willkommen</Link>
        </li>
        {/*<li>*/}
        {/*  <Link*/}
        {/*    to="/bewerbung"*/}
        {/*    className={activeTab === "bewerbung" ? "active" : ""}*/}
        {/*    onClick={() => setActiveTab("bewerbung")}*/}
        {/*  >Bewerbung</Link>*/}
        {/*</li>*/}
        <li>
          <Link
            to="/preise"
            className={activeTab === "preise" ? "active" : ""}
            onClick={() => setActiveTab("preise")}
          >Preise</Link>
        </li>
        <li>
          <Link
            to="/kontakt"
            className={activeTab === "kontakt" ? "active" : ""}
            onClick={() => setActiveTab("kontakt")}
          >Kontakt</Link>
        </li>
      </ul>
    </div>
  );
}
