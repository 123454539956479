import React from "react";
import "./ResponsiveLogo.scss";

export default function ResponsiveLogo() {
  return (
    <div className="responsive-logo">
      <img src="/img/lopez_text.png" className="logo-left" alt="Haarkreation Susana Cintrano Lopez Friseurmeisterin" />
      <img src="/img/lopez.png" className="logo-right" alt="Logo Haarkreation Lopez" />
    </div>
  );
}
