import './ContactPage.scss';
import Image from "../../components/Image";

const googleMapsLink = "https://www.google.de/maps/place/Haarkreation/@52.470034,13.3226053,17z/data=!3m1!4b1!4m15!1m8!3m7!1s0x47a8507e99f383d3:0xd5541347bb2d2134!2sHaarkreation!8m2!3d52.470034!4d13.3251856!10e1!16s%2Fg%2F1thk7s6q!3m5!1s0x47a8507e99f383d3:0xd5541347bb2d2134!8m2!3d52.470034!4d13.3251856!16s%2Fg%2F1thk7s6q";

export default function ContactPage() {

  return (
    <div className="contact-root">
      <div className="contact-details">
        <h1>Öffnungszeiten</h1>
  
        <table>
          <tbody>
            <tr>
              <td>Dienstag bis Freitag</td>
              <td>9:00 bis 18:30 Uhr</td>
            </tr>
            <tr>
              <td>Samstags</td>
              <td>9:00 bis 14:00 Uhr</td>
            </tr>
          </tbody>
        </table>
  
        <p>
          Telefonische Anmeldung bitte unter <a href="tel:+49308591252">030 / 859 12 52</a>
        </p>
  
        <div>
          <strong>Haarkreation Lopez</strong><br />
          Stubenrauchstr. 9<br />
          12161 Berlin<br />
          <a href={googleMapsLink} target="_blank" rel="noreferrer">
            Adresse in Google Maps öffnen
          </a>
        </div>
      </div>

      <div className="contact-map">
        <Image
          src={"img/map.png"}
          className="contact-map-image"
          alt="Stubenrauchstraße 9, 12161 Berlin"
          link={{
            href: googleMapsLink,
            target: "_blank",
            rel: "noreferrer"
          }}
        />
      </div>
    </div>
  );
}
