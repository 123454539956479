import React from 'react';
// import logo from './logo.svg';
import './App.scss';
import NavBar from "./components/NavBar";
import {Outlet} from "react-router-dom";
import Footer from "./components/Footer";
import ContextProvider from "./components/ContextProvider";
import ResponsiveLogo from "./components/ResponsiveLogo";

function App() {
  return (
    <ContextProvider>
      <div className="app">
        <header className="">
          <ResponsiveLogo />

          <NavBar />
        </header>

        <div className="content-container">
          <Outlet />
        </div>

        <Footer />
      </div>
    </ContextProvider>
  );
}

export default App;
