export default function ApplicationPage() {
  return (<div>
      <h1>Bewerbung</h1>

      <p>
        Haarkreation Lopez ist ein kleiner Friseursalon im Herzen von Friedenau und sucht zur Verstärkung seines Teams
        eine engagierte und erfahrene Friseurin/einen engagierten und erfahrenen Friseur.
      </p>
      <h3>Aufgaben:</h3>
      <ul>
        <li>Durchführung klassischer und moderner Haarschnitte</li>
        <li>Färben, Tönen und Strähnchen</li>
        <li>Beratung und Betreuung der Kunden</li>
        <li>Styling und Hochsteckfrisuren</li>
        <li>Pflege- und Wellnessanwendungen für Haar und Kopfhaut</li>
      </ul>

      <h3>Anforderungen:</h3>
      <ul>
        <li>Abgeschlossene Ausbildung als Friseur/in</li>
        <li>Mehrjährige Berufserfahrung in einem Salon</li>
        <li>Fachkenntnisse in den Bereichen Haarschnitte, Colorationen und Styling</li>
        <li>Gute Kommunikationsfähigkeiten und freundliches Auftreten</li>
        <li>Kreativität und Sinn für Trends</li>
        <li>Selbstständige und zuverlässige Arbeitsweise</li>
      </ul>
      <h3>Wir bieten:</h3>
      <ul>
        <li>Angenehmes Arbeitsklima in einem kleinen, familiären Team</li>
        <li>Attraktives Gehalt und faire Arbeitsbedingungen</li>
        <li>Weiterbildungsmöglichkeiten und Schulungen</li>
        <li>Gestaltungsspielraum für eigene Ideen und Kreativität</li>
        <li>Flexible Arbeitszeiten (Teilzeit oder Vollzeit)</li>
      </ul>
      <p>
        Wenn Sie Freude an Ihrem Beruf haben, gerne eigenverantwortlich arbeiten und bereit sind, sich in unserem Salon
        einzubringen, freuen wir uns auf Ihre Bewerbung.
      </p>
      <p>
        Bitte senden Sie Ihren Lebenslauf und ein aussagekräftiges Anschreiben an die folgende E-Mail-Adresse:
        info@haarkreation-lopez.de.
      </p>
      <p>
        Haarkreation Lopez<br/>
        Stubenrauchstr. 9<br/>
        12161 Berlin<br/>
        Tel: <a href="tel:+49308591252">030 / 859 12 52</a>
      </p>
      <p>
        Wir danken für Ihr Interesse und werden uns nach Eingang Ihrer Bewerbung umgehend mit Ihnen in Verbindung
        setzen.
      </p>
      <p>
        Mit freundlichen Grüßen,
        Ihr Haarkreation Lopez Team
      </p>

    </div>);
}
