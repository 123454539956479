import {PriceBlockPropsType} from "./PriceBlock.types";
import "./PriceBlock.scss";
import {Fragment} from "react";

export default function PriceBlock({ title, items }: PriceBlockPropsType) {



  return (
    <div className="price-block">
      <h2>{ title }</h2>

      <table>
        <tbody>
          { items.map((item, index) => (
            <Fragment key={`price-block-item-fragment-${title}-${index}`}>
              <tr className="price-block-item" key={`price-block-item-${title}-${index}`}>
                <td className="price-block-item-label-col">{ item.label }</td>
                <td className="price-block-item-sub-label-col">{ item.subLabel || "" }</td>
                <td className="price-block-item-price-col">{ item.price }</td>
              </tr>

              { item.variants && item.variants.map((variant, index) => (
                <tr className="price-block-item" key={`price-block-item-${title}-variants-${index}`}>
                  <td className="price-block-item-label-col"></td>
                  <td className="price-block-item-sub-label-col">{ variant.subLabel || "" }</td>
                  <td className="price-block-item-price-col">{ variant.price }</td>
                </tr>
              )) }
            </Fragment>
          )) }
        </tbody>
      </table>
    </div>
  );
}
