import {createBrowserRouter} from "react-router-dom";
import App from "./App";
import PricePage from "./pages/PricePage";
import WelcomePage from "./pages/WelcomePage";
import ContactPage from "./pages/ContactPage";
import ErrorPage from "./pages/ErrorPage";
import PrivacyPage from "./pages/PrivacyPage";
import LegalPage from "./pages/LegalPage";
import ApplicationPage from "./pages/ApplicationPage";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <WelcomePage />,
      }, {
        path: "/preise",
        element: <PricePage />,
      }, {
        path: "/kontakt",
        element: <ContactPage />,
      }, {
        path: "/impressum",
        element: <LegalPage />,
      }, {
        path: "/datenschutz",
        element: <PrivacyPage />,
      }, {
        path: "/bewerbung",
        element: <ApplicationPage />,
      },
    ]
  },
]);

export default router;
